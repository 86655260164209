* {
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

/*container*/
.container {
    position: relative;
    max-width: 1440px;
    width: 100%;
    padding-left: 150px;
    padding-right: 150px;
    margin-left: auto;
    margin-right: auto;
}

.container .container {
    padding-left: 0px;
    padding-right: 0px;
}

/*html*/
html {
    overflow-x: hidden;
}

/*page*/
.page {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
    font-size: 16px;
    background-color: #fff;
}

/*orange-bck*/
.orange-bck::after {
    position: absolute;
    top: 0px;
    left: 63%;
    z-index: 1;
    width: 100vw;
    height: 100%;
    content: '';
    z-index: -1;
    background-color: #F85628;
}

.orange-bck-2::before {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    width: 37%;
    height: 100%;
    content: '';
    z-index: -1;
    background-color: #F85628;
}

/*page-header*/
.page-header {
    position: relative;
    width: 100%;
}

.page-header .page-header-content {
    position: relative;
    z-index: 10;
    padding-top: 56px;
    padding-bottom: 20px;
}

.page-header .page-logo .logo-img {
    width: 151.14px;
    height: 40px;
}

.page-header .page-nav {
    margin-left: auto;
}

.page-header .nav-menu {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.page-header .nav-menu .menu-item {
    font-weight: 500;
    line-height: 20px;
    padding: 14px 28px;
    color: #fff;
}

.page-header .nav-menu .menu-item:not(.blog) {
    text-decoration: underline;
}

.page-header .nav-menu .menu-item.blog {
    background-color: #fff;
    border-radius: 12px;
    color: #053459;
    font-weight: 600;
    line-height: 20px;
}

.page-header .nav-menu .menu-item a:visited,
.page-header .nav-menu .menu-item a:active,
.page-header .nav-menu .menu-item a {
    color: inherit;
    text-decoration: none;
}

/*page-main*/
.page-main {
    position: relative;
    overflow: hidden;
}

/*page-footer*/
.page-footer {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #F85628;
}

.page-footer .footer-logo {
    margin-right: auto;
}

.page-footer .footer-logo .logo {
    width: 151.14px;
    height: 40px;
}

.page-footer .footer-text p {
    width: 550px;
    margin: 0px;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #fff;
    text-align: center;
}

.page-footer .footer-social-medias {
    margin-left: auto;
}

.page-footer .footer-social-medias a {
    display: inline-block;
    width: auto;
}

.page-footer .footer-social-medias a:not(:last-child) {
    margin-right: 20px;
}

/*cg-box*/
.cg-box {
    position: relative;
    z-index: 100;
}

.cg-box-content {
    padding-top: 158px;
    padding-bottom: 158px;
}

.cg-box-content-2 {
    padding-top: 81px;
    padding-bottom: 0px;
}

.cg-box-content-3 {
    padding-top: 81px;
    padding-bottom: 0;
}

.cg-box-content- {
    max-width: 900px;
    width: 100%;
    margin-right: auto;
}

.cg-box-content-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 900px;
    width: 100%;
    margin-right: auto;
    padding-left: 50px;
}

.cg-box-title .title-2 {
    font-weight: 700;
    font-size: 40px;
    text-align: center;
    line-height: 48px;
    color: #053459;
}

.cg-box .cg-box-description .description {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #676F75;
    margin-top: 8px;
    margin-bottom: 8px;
}

.cg-box-description .description {
    margin: 0px;
}

.cg-box .cg-box-buttons {
    display: inline-block;
    width: auto;
    margin-top: 32px;
}

.step-by-step+.text-center .cg-box-buttons {
    margin-top: 110px;
}

.cg-box .btn-go:active,
.cg-box .btn-go:visited,
.cg-box .btn-go {
    display: inline-block;
    width: auto;
    border-radius: 16px;
    padding: 20px 40px;
    text-align: center;
    background-color: #F85628;
    line-height: 24px;
    font-size: 20px;
    color: #FFF;
    text-decoration: none;
}

/*home*/
.cg-box#home {
    position: relative;
}

.cg-box#home .cg-box-content {
    padding-top: 104px;
    padding-bottom: 243px;
}

.cg-box#home .cg-box-title {
    width: 685px;
}

.cg-box-title .title {
    font-size: 57px;
    line-height: 68px;
    font-weight: 300;
    padding: 0px;
    margin: 0px;
}


.cg-box#home .cg-box-title-2 {
    width: 585px;
    text-align: center;
}

.cg-box#home .cg-box-title .title {
    margin: 0px;
    padding: 0px;
    color: #053459;
}

.cg-box#home .cg-box-description {
    width: 457px;
    margin-top: 48px;
}

.cg-box#home .cg-box-image {
    position: absolute;
    top: 30px;
    z-index: 2;
    right: -320.59px;
    overflow: hidden;
}

.orange{
    background-color: #F85628;
    padding: 0px 20px;
    color: #fff;
}


.cg-box#home .cg-box-image .app-image {
    width: 1123.59px;
    height: 880px;
    
}

/*why-use*/
.cg-box#why-use .title {
    text-align: center;
}

.cg-box#why-use .properties {
    margin-top: 72px;
    margin-bottom: 158px;
}

/*properties*/
.properties .item {
    display: flex;
    flex-direction: column;
    padding: 9px;
}

.properties .item-icon {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 96px;
    height: 96px;
    margin-bottom: 0px;
}

.properties .item .item-title {
    margin: 0px;
    line-height: 24px;
    font-size: 20px;
    color: #535B61;
    margin-top: 32px;
}

/*youtube-video*/
#youtube-embed .cg-box-content {
    padding-top: 0px;
    padding-bottom: 0px;
}

#youtube-embed .youtube-video {
    padding-top: 78px;
    padding-bottom: 78px;
}

#youtube-embed .youtube-video iframe {
    width: 946px;
    height: 564.28px;
    border-radius: 24px;
    overflow: hidden;
    margin: auto;
}

/*step-by-step*/
.cg-box#step-by-step .step-by-step .step .mobile-step-line {
    display: none;
}

.step-by-step {
    margin-top: 81px;

}

.step-by-step .step {
    text-align: center;
    width: 25%;
}

.step-by-step .step .image {
    max-width: 100%;
    width: 224px;
    height: 467.03px;
    margin: 29px auto 65px auto;
}

.step-by-step .step-details {
    position: relative;
}

.step-by-step .step .step-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    color: #fff;
    font-size: 24px;
    line-height: 28px;
    font-weight: 700;
    background-color: #053459;
}

.step-by-step .step .line {
    width: calc(50% - 28px);
}

.step-by-step .step .line.to-right .arrows {
    margin-left: 9px;
}

.step-by-step .step .line.to-left .arrows {
    margin-right: 9px;
}

.step-by-step .step:first-child .step-number {
    margin-left: auto;
}

.step-by-step .step:last-child .step-number {
    margin-right: auto;
}

.step-by-step .step-content {
    margin-top: 24px;
}

.step-by-step .step-content h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 0px;
    color: #212529;
}

.step-by-step .step-content .step-description {
    margin-top: 16px;
}

.step-by-step .step-content .description {
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #676F75;
    width: 224px;
    margin: 0px auto;
}

.big-cont {
    padding: 0px 100px 0px 100px !important;
}

.mockup-2 {
    height: 800px;
    z-index: 100;
}

.mockup-2-content-left {
    display: flex;
    flex: 2;
    align-items: center;
    height: 100%;
}

.mockup-2-content-right {
    display: flex;
    flex: 3;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding-right: 200px;
}

.mockup-2-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.bck-orange-2 {
    position: absolute;
    top: 0px;
    left: 0px;
    background: #F85628;
    width: 37%;
    height: 100%;
    z-index: -1;
}

.description-source {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: #676F75;
    font-style: italic;
}

.provas {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 100px 50px;
    width: 100%;
    background-color: #f7f9fa;
}

.prova-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin: 0px;
    color: #053459;
    text-align: center;
}

.prova-container {
    display: flex;
    gap: 50px;
    box-sizing: border-box;
    flex-wrap: wrap;
    padding: 100px 50px;
    width: 100%;
    background-color: #f7f9fa;
    justify-content: space-around;
    align-items: center;
}

.prova {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    flex-direction: column;
    flex: 1;
    min-height: 330px;
    min-width: 300px;
    border-radius: 15px;
    box-shadow: 0px 16px 32px rgba(33, 37, 41, 0.08);
    border: 2px solid #E4E8EB;
    box-sizing: border-box;
    padding: 20px;
    transition: 300ms ease all;
}

.prova-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.prova-description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #676F75;
    margin-top: 20px;
    text-align: justify;
}

.prova-footer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.prova-issuer {
    font-size: 16px;
    font-weight: 400;
    color: #676F75;
    text-align: justify;
}

.prova-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    border-radius: 25px;
    border: 1px solid #E4E8EB;
}

.prova:hover {
    border: 2px solid #F85628;
    box-sizing: border-box;
    transition: 300ms ease all;
}

/*features*/
#features {
    background: linear-gradient(0deg, #F7F9FA, #F7F9FA), linear-gradient(180deg, #F7F9FA 0%, #FFFFFF 100%);
}

#features .features {
    width: 100%;
    margin-top: 48px;
    align-items: flex-start;
}

#features .features .features-list {
    width: 300px;
}

#features .feature {
    flex: 1 1 300px;
    border-radius: 12px;
    padding: 14px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 7px 0px;
    color: #A7B0B8;
    border: 1px solid #eff1f3;
    cursor: pointer;
    scroll-snap-align: start;
}

#features .feature:last-child {
    scroll-snap-align: end;
}

#features .feature.current {
    background-color: #F85628;
    color: #fff;
    border-color: #F85628;
}

#features .feature.current .icon {
    stroke: #fff;
}

#features .feature .icon {
    stroke: #A7B0B8;
}

#features .feature-icon {
    margin-right: 15.25px;
}

#features .feature .arrow {
    margin-left: auto;
}

#features .feature .feature-content {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}

#features .features-descriptions {
    width: calc(100% - 300px);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 80px;
}

#features .features-descriptions .svg-grafic {
    overflow: hidden;
    border-radius: 12px;
}

#features .features-descriptions .feature-grafic {
    padding: 22px;
    border-radius: 12px;
    border: 1px solid #eff1f3;
}

#features .features-descriptions .feature-image {
    width: 507px;
    height: 344px;
    border-radius: 12px;
    overflow: hidden;
}

#features .features-descriptions .feature-image:not(.show-img) {
    display: none;
}

#features .features-descriptions .feature-info {
    padding-left: 50px;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
    color: #fff;
    margin-top: auto;
    margin-bottom: auto;
}

#features .features-descriptions .feature-info .description:not(.show-des) {
    display: none;
}

/*starting*/
#starting .step {
    width: 33.33%;
}

#starting .step:not(:last-child) {
    width: calc(33.33% - 30px);
    margin-right: 30px;
}

#starting .step-image .image {
    width: 199px;
    height: 141px;
    margin-bottom: 0px;
}

#starting .step-by-step .step-content .description {
    width: 100%;
}

/*plans*/
#plans {
    background-color: #F7F9FA;
}

#plans .cg-box-content {
    padding-bottom: 0px;
}

#plans .cg-box-title {
    margin-bottom: 16px;
}

#plans .cg-box-content- {
    width: 750px;
    margin: auto;
}

#plans .carousel {
    position: relative;
    margin-top: 80px;
}

#plans .list-plans {
    position: relative;
    width: auto;
    flex-direction: row;
    transition: all 0.5s ease-in-out;
}

#plans .list-plans::-webkit-scrollbar {
    width: 0 !important
}

#plans .list-plans {
    overflow: -moz-scrollbars-none;
}

#plans .list-plans {
    -ms-overflow-style: none;
}

#plans .plans {
    position: relative;
    width: 100%;
}

#plans .plans::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 142px;
    bottom: 0px;
    right: 0px;
    z-index: -1;
    background-color: #F85628;
}

#plans .plan {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;
    min-width: calc(330px - 30px);
    max-width: calc(330px - 30px);
    flex-grow: 1;
    height: auto;
    min-height: 340px;
    border-radius: 24px;
    background-color: #fff;
    margin-right: 15px;
    margin-left: 15px;
    transition: all 0.5s ease-in-out;
}

/* 
.plan:hover {
    transform: scale(1.05);
    transition: all 0.5s ease-in-out;
} */

#plans .plan .plan-title {
    margin-top: 32px;
}

#plans .plan-title h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin: 0px;
    color: #053459;
}

#plans .plan-price {
    margin-top: 20px;
    margin-bottom: 20px;
}

#plans .plan-price .price {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #053459;
}

#plans .plan-info,
#plans .plan-price .month {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-left: 8px;
    color: #A7B0B8;
}

#plans .plan-info .icon {
    margin-right: 14.34px;
}

#plans .plan-btn {
    padding-top: 10px;

}

.btn-comprar {
    background: #F85628;
    color: #fff !important;
    padding: 20px 20px !important;
}

#plans .btn:active,
#plans .btn:visited,
#plans .btn {
    display: inline-block;
    text-decoration: none;
    color: #F85628;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    border-radius: 16px;
    padding: 20px 40px 20px 40px;
}

#plans .plan:hover {
    transition: 0.25s;
    background-color: #053459;
}

#plans .plan:hover h4,
#plans .plan:hover .plan-price .price {
    color: #fff;
}

#plans .plan:hover .plan-info,
#plans .plan:hover .plan-price .month {
    color: rgba(255, 255, 255, 0.80);
}

#plans .plan:hover .btn:active,
#plans .plan:hover .btn:visited,
#plans .plan:hover .btn {
    background: #F85628;
    color: #fff;
}

#plans .cg-box-description.info {
    padding-top: 41px;
    padding-bottom: 158px;
    background: #F85628;
}

#plans .cg-box-description.info .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(255, 255, 255, 0.8);
}

#plans .navigation .nav {
    position: absolute;
    top: 50%;
    width: 80px;
    height: 80px;
    background-color: #fff;
    border: 1px solid #E4E8EB;
    box-sizing: border-box;
    box-shadow: 0px 16px 32px rgba(33, 37, 41, 0.08);
    border-radius: 12px;
    transform: translateY(-50%);
    z-index: 10;
}

#plans .navigation .nav.left-menu {
    left: -40px;
}

#plans .navigation .nav.right-menu {
    right: -40px;
}

/*displays*/
.d-flex {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

/*flex-properties*/
/*-- justify-content*/
.j-center {
    justify-content: center;
}

.j-end {
    justify-content: flex-end;
}

.j-around {
    justify-content: space-around;
}

/*-- align-items*/
.al-center {
    align-items: center;
}

.al-end {
    align-items: flex-end;
}

.al-start {
    align-items: flex-start;
}

/*flex-nowrap*/
.flex-nowrap {
    flex-wrap: nowrap;
}

/*flex-wrap*/
.flex-wrap {
    flex-wrap: wrap;
}

/*flex-direction*/
.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

/*text align*/
.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.flex-column {
    flex-direction: column;
}

/*orange-fold*/
.orange-fold {
    background-color: #F85628;
    height: 330px;
    margin-top: -300px;

}


.icon-yellow,
.icon-green,
.icon-blue,
.icon-orange,
.icon-black {
    width: 95px;
    height: 95px;
    background-color: #FDFBE0;
    border-radius: 15px;
    text-align: center;
    padding-top: 25px;
}

.icon-green {
    background-color: #f2fcf7;
}

.icon-blue {
    background-color: #f5fbff;
}

.icon-orange {
    background-color: #fff7f5;
}

.icon-black {
    background-color: #f5f9fc;
}

.cg-box#step-by-step .step-by-step .step-details-h {
    height: 200px;
}



/*Resposivo*/
/*min-width:1000px*/
@media only screen and (min-width:1000px) {
    .d-visible-xs {
        display: none;
    }
}

@media screen and (min-width: 1259px){
    .carousel {
        padding-left: 220px !important;
        padding-right: 220px !important;
    }
}

/*max-width:1259px*/
@media only screen and (max-width:1259px) {
    .container {
        max-width: 1024px;
        padding-left: 50px;
        padding-right: 50px;
    }

    .mockup-2 {
        width: 500px;
        z-index: 100;
    }
    .mockup-2-content-right {
        padding: 0px;
    }

    /*home*/
    .cg-box-content- {
        width: 600px;
        max-width: 100%;
    }

    .cg-box#home .cg-box-title {
        width: 560px;
        max-width: 100%;
    }

    .cg-box#home .cg-box-image {
        display: flex;
        right: -328px;
        top: 50%;
        transform: translateY(-50%);
    }

    .cg-box#home .cg-box-image .app-image {
        width: 900px;
        height: auto;
    }

    /*youtube-embed*/
    #youtube-embed .youtube-video iframe {
        width: 670.58px;
        height: 400px;
    }

    /*step-by-step*/
    .step-by-step .step .image {
        width: 167.86px;
        height: 350px;
    }

    .step-by-step .step-content .description {
        padding-left: 15px;
        padding-right: 15px;
    }

    /*features*/
    #features .features .features-list {
        width: 280px;
    }

    #features .features-descriptions {
        width: calc(100% - 280px);
        margin-left: 20px;
    }

    #features .features-descriptions .svg-grafic {
        width: 360px;
        height: auto;
    }

    /*plans*/
    #plans .plan {
        min-width: 300px;
        max-width: 300px;
    }
}

/*max-width:1150px*/
@media only screen and (max-width:1150px) {

    /**/
    .container {
        max-width: 1000px;
    }

    /*home*/
    .cg-box#home .cg-box-description {
        max-width: 357px;
    }
}

/*max-width:1000px*/
@media only screen and (max-width:1000px) {

    .orange-bck::after {
        display: none;
    }

    .mockup-2 {
        width: 350px;
        height: 600px;
    }

    .bck-orange-2 {
        display: none;
    }

    .container {
        max-width: 740px;
        padding-right: 20px;
        padding-left: 20px;
    }

    .d-visible-md {
        display: none;
    }

    .orange-fold {
        background-color: #F85628;
        height: 10px;
        margin-top: 86px;
    }

    /*page-header*/
    .page-header {
        background: linear-gradient(0deg, #F7F9FA, #F7F9FA), linear-gradient(180deg, #FFFFFF 0%, #F7F9FA 100%);
    }

    .page-header {
        height: auto;
    }

    .page-header .page-header-content .logo-img {
        width: 90.69px;
        height: 24px;
    }

    .page-header .page-header-content {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .page-header .nav-menu .menu-item {
        color: #676F75;
        padding: 11px 20px;
    }

    .page-header .nav-menu .menu-item.blog {
        background-color: #F0F3F5;
        color: #053459;
    }

    /*cg-box*/
    .cg-box .cg-box-content {
        padding-top: 120px;
        padding-bottom: 120px;
    }

    .cg-box .cg-box-title {
        width: 600px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 80px;
    }

    .cg-box .cg-box-title .title {
        font-size: 32px;
        line-height: 38px;
    }

    /*home*/
    .cg-box#home {
        background: linear-gradient(0deg, #F7F9FA, #F7F9FA), linear-gradient(180deg, #FFFFFF 0%, #F7F9FA 100%);
    }

    .cg-box#home .cg-box-content- {
        max-width: 400px;
        width: 100%;
    }


    .cg-box#home .cg-box-image {
        margin-top: 80px;
        right: -275px;
    }

    .cg-box#home .cg-box-image .app-image {
        width: 700px;
    }

    .cg-box#home .cg-box-description,
    .cg-box#home .cg-box-title {
        width: 100%;
    }

    .cg-box#home .cg-box-title .title {
        font-size: 48px;
        line-height: 57.6px;
        font-weight: 300;
    }

    /*why-use*/
    .cg-box#why-use .properties {
        flex-wrap: wrap;
        margin: 0px;
    }

    .properties .item {
        flex: 1 1 50%;
    }

    .properties .item:not(:last-child) {
        margin-bottom: 56px;
    }

    .properties .item .item-title {
        text-align: center;
        margin-top: 24px;
    }

    /*youtube-embed*/
    #youtube-embed .youtube-video iframe {
        width: 670.60px;
        height: 400px;
    }

    /*step-by-step*/
    .cg-box#step-by-step .step-by-step {
        flex-direction: column;
    }

    .cg-box#step-by-step .step-by-step .step {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
    }

    .cg-box#step-by-step .step-by-step .step .step-number {
        width: 48px;
        height: 48px;
        font-size: 20px;
        line-height: 24px;
    }

    .cg-box#step-by-step .step-by-step .step-content h4 {
        font-size: 18px;
        line-height: 22px;
    }

    .cg-box#step-by-step .step-by-step .step-content .description {
        font-size: 12px;
        line-height: 18px;
    }

    .cg-box#step-by-step .step-by-step .step .image {
        min-width: 220px;
        max-width: 220px;
        height: auto;
        margin-right: 16px;
        margin-top: 41px;
        margin-bottom: 41px;
    }

    .cg-box#step-by-step .step-by-step .step:first-child .step-image .image {
        margin-top: 0px;
    }

    .cg-box#step-by-step .step-by-step .step:last-child .step-image .image {
        margin-bottom: 0px;
    }

    .cg-box#step-by-step .step-by-step .step-details {
        z-index: 1;
        padding-bottom: 24.2px;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
    }

    .cg-box#step-by-step .step-by-step .step-content .description {
        width: 100%;
    }

    .cg-box#step-by-step .step-by-step .step .mobile-step-line {
        display: inline-block;
        position: absolute;
        width: calc(100% - 220px);
        height: 100%;
        right: 0px;
    }

    .cg-box#step-by-step .step-by-step .step .mobile-step-line .step-line-arrow {
        height: 50%;
    }

    .cg-box#step-by-step .step-by-step .step .step-line-arrow .line-m svg,
    .cg-box#step-by-step .step-by-step .step .step-line-arrow .line-m rect,
    .cg-box#step-by-step .step-by-step .step .step-line-arrow .line-m {
        height: 100% !important;
    }

    .cg-box#step-by-step .step-by-step .step .step-line-arrow.to-bottom {
        justify-content: flex-end;
        align-items: center;
    }

    .cg-box#step-by-step .step-by-step .step .step-line-arrow.to-top {
        justify-content: flex-start;
        align-items: center;
    }

    .cg-box#step-by-step .step-by-step .step .mobile-step-line .to-top .arrows {
        margin-bottom: 9px;
    }

    .cg-box#step-by-step .step-by-step .step .mobile-step-line .to-bottom .arrows {
        margin-top: 9px;
    }

    .cg-box#step-by-step .step-by-step .step .line:not(.to-bottom, .to-top) {
        display: none;
    }

    .cg-box#step-by-step .step-by-step .step:first-child .step-number,
    .cg-box#step-by-step .step-by-step .step:last-child .step-number {
        margin: auto;
    }

    .cg-box#step-by-step .step-by-step .step:last-child .step-line-arrow.to-bottom,
    .cg-box#step-by-step .step-by-step .step:first-child .step-line-arrow.to-top {
        visibility: hidden;
    }


    /*features*/
    .cg-box#features .cg-box-title {
        margin-bottom: 40px;
    }

    .cg-box#features .features {
        flex-direction: column;
        margin: 0px;
    }

    .cg-box#features .features-list {
        position: relative;
        width: auto;
        max-width: 100%;
        overflow: auto hidden;
        scrollbar-width: none;
        scroll-snap-type: x mandatory;
        flex-direction: row;
        flex-wrap: nowrap;
        appearance: none;
    }

    .cg-box#features .features-list .feature {
        min-width: max-content;
        align-items: center;
        margin-right: 16px;
    }

    #features .feature .arrow {
        display: none;
    }

    #features .feature .feature-content {
        font-size: 14px;
        line-height: 17px;
    }

    #features .features-descriptions {
        width: 100%;
        margin-top: 60px;
        margin-left: 0px;
        flex-wrap: wrap;
    }

    #features .features-descriptions .feature-image,
    #features .features-descriptions .svg-grafic {
        width: 380px;
        max-width: 100%;
        height: auto;
    }

    #features .features-descriptions .feature-grafic {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    #features .features-descriptions .feature-info {
        font-size: 18px;
        line-height: 27px;
        padding: 0px 30px;
        color: #676F75;
        max-width: 380px;
        margin-left: auto;
        margin-right: auto;
    }

    /*plans*/
    #plans .navigation .nav {
        width: 60px;
        height: 60px;
    }

    #plans .navigation .nav.left-menu {
        left: -30px;
    }

    #plans .navigation .nav.right-menu {
        right: -30px;
    }

}

/*max-width:740px*/
@media only screen and (max-width: 740px) {
    .mockup-2 {
        width: 100%;
        z-index: 100;
    }

    .mockup-2-content-right {
        padding: 50px 0px;
    }
    
    .cg-box-title-2 {
        margin-bottom: 30px !important;
    }

    .big-cont {
        padding: 0 0 0 0 !important;
        margin: auto;
    }

    .container {
        min-width: 320px;
        max-width: 375px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .container .container {
        padding-left: 0px;
        padding-right: 0px;
    }

    /*home*/
    .cg-box#home .cg-box-content {
        display: flex;
        flex-direction: column;
        padding-top: 56px;
        padding-bottom: 56px;
    }

    .cg-box#home .cg-box-image {
        position: relative;
        right: 32px;
        height: 383px;
        margin-top: 0px;
        margin-bottom: 0px;
        overflow: visible;
        transform: initial;
        order: -1;
    }

    .cg-box#home .cg-box-image .app-image {
        width: 633.3px;
        height: 496px;
    }

    /*why-use*/
    .cg-box#why-use .properties {
        flex-wrap: wrap;
        margin: 0px;
    }

    .properties .item {
        flex: 1 1 100%;
    }

    /*step-by-step*/
    .cg-box#step-by-step .step-by-step .step .image {
        min-width: 160px;
        max-width: 160px;
        height: 333.59px;
        margin-right: 16px;
        margin-top: 41px;
        margin-bottom: 41px;
    }

    .cg-box#step-by-step .step-by-step .step .mobile-step-line {
        width: calc(100% - 176px);
    }

    /*youtube-embed*/
    #youtube-embed .youtube-video {
        padding-top: 16.18px;
        padding-bottom: 16.18px;
    }

    #youtube-embed .youtube-video iframe {
        width: 335px;
        height: 199.82px;
    }

    .mockup-2-content {
        flex-direction: column;
    }

    .cg-box-title-2 .title {
        width: 500px;
        max-width: 100%;
        margin: 0px 20px;
    }

    /*starting*/
    .cg-box#starting .step-by-step {
        flex-direction: column;
    }

    .cg-box#starting .step {
        width: 100%;
        flex-direction: column;
    }

    .cg-box#starting .step:not(:last-child) {
        margin-bottom: 82px;
    }

    .cg-box#starting .step-image .image {
        width: 180.9px;
        height: 127.88px;
        margin: 0px;
    }

    .cg-box#starting .step-by-step .step-content h4 {
        font-size: 24px;
        line-height: 28px;
    }

    .cg-box#starting .step-by-step .step-content .description {
        font-size: 16px;
        line-height: 24px;
    }

    /*plans*/
    .cg-box#plans .cg-box-content- {
        width: 100%;
    }

    .cg-box#plans .navigation .nav {
        width: 48px;
        height: 48px;
    }

    .cg-box#plans .navigation .nav.left-menu {
        left: -12px;
    }

    .cg-box#plans .navigation .nav.right-menu {
        right: -12px;
    }

    .cg-box#plans .plan {
        position: relative;
        max-width: calc(100% - 30px);
        min-width: calc(100% - 30px);
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;
        height: 284px;
    }


    /*page-footer*/
    .page-footer .page-footer-content {
        flex-direction: column;
        align-items: center;
    }

    .page-footer .footer-social-medias,
    .page-footer .footer-logo {
        margin: initial;
    }

    .page-footer .footer-logo {
        margin-bottom: 56px;
    }

    .page-footer .footer-logo .logo {
        width: 151.14px;
        height: 40px;
    }

    .page-footer .footer-social-medias {
        margin-top: 56px;
    }

    .page-footer .footer-text p {
        width: 100%;
    }
}